.container_home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
}

.main_heading {
    font-size: 10em;
    color: #ff8000;
    font-family: staat;
    margin-bottom: 20px;
    margin-top: 0 ;
}
@media (max-width: 767px) {
    .main_heading {
      font-size: 4.8em;
      margin-bottom: 10px;
    }
}

.subHeading{
    font-size: 3em;
    color: #fff;
    font-family: spline;
    margin-bottom: 3rem;
    margin-left: 5rem;
    margin-right: 5rem;
    /* margin-top: 5rem; */
}
@media (max-width: 767px) {
    .subHeading {
      font-size: 1.3em;
      margin-bottom: 0px;
      margin-left: 0rem;
    margin-right: 0rem;
    }
}
.icon_image{
    height: 20vh;
    width: 40vw;
    object-fit: contain;
    display: block;
}


.scroll_down{
    height: 10vh;
    width: 10vw;
    color: #ff8001;
    margin-top: 4vh;
    margin-bottom: 1.5vh;
    transition: transform 0.3s ease; /* Smooth transformation transition */

}
.scroll_down:hover{
    cursor: pointer;
    transform: scale(1.5) translateY(1vh);  
    transition: 0.5s;
    color: #ff9f1c;

}
@media (max-width: 767px) {
    .scroll_down {
      cursor: pointer;
    transform: scale(1.5) translateY(1vh);  
    transition: 0.5s;
    color: #ff8001;
      margin-top: 0;
    }
}



/* CSS */
