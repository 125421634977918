.button_Container{
    max-width: 100%;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    align-items: center;
}
@media (max-width: 767px) {
    .button_Container {
        flex-direction: column;
    }
}

.main_btn{
    width: 40vw;
    height: 70vh;
    border: 5px solid #ff9f1c;
    border-radius:12%;
    background-color: black;
    text-align: center;
    font-family: staat;
    font-weight: 500;
    font-size: 3rem;
    overflow: hidden;
    position: relative;
}
@media (max-width: 767px) {
    .main_btn {
        width: 90vw;
        height: 40vh;
    }
}


.before_container{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 1;
}

.main_text_card{
    font-size: 3vw;
    margin: 2rem;
    font-weight: 600;
}

@media (max-width: 767px) {
    .main_text_card {
        font-size: 7vw;
        margin: 1rem;
        font-weight: 500;
    }
}


.subtext_card{
    font-weight: 300;
    font-size: 2vw;
    margin: 1.5rem;
}
@media (max-width: 767px) {
    .subtext_card {
        font-weight: 200;
        font-size: 4.5vw;
        margin: 1rem;
    }
}


/*  */

.my_pointer{
  display: none;
}

.my-btn {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .my-btn {
        font-size: 14px;
        font-weight: 500;
        margin-top: 25px;
    }
}

.my-btn:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.my-btn:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.my-btn:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.my-btn:focus {
  outline: 1px transparent;
}

.my-btn:before {
  display: none;
}

.my-btn:-webkit-details-marker {
  display: none;
}

.my_pointer{
  display: none;
}

.btn-img{
    height: 40%;
    width: 40%;
    object-fit: cover;
}

.compute_button{
  display: flex;
  gap: 1rem;
}